<template>
  <div
    id="login-page"
    class="login-page"
    :class="lang == 'en' || lang == undefined ? '' : 'text-right'"
  >
    <v-row style="height: 100vh">
      <v-col class="side-page d-none d-md-block" cols="12" md="6" sm="12">
        <svg
          version="1.1"
          id="e7b3b0c3-1e4f-480c-906c-c03021e90d10"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 991.1 770.2"
          style="enable-background: new 0 0 991.1 770.2"
          xml:space="preserve"
        >
          <title>{{ $t("professor") }}</title>
          <path
            class="st0"
            d="M289.6,0L131.7,85.5c11.1,19.2,4.6,43.8-14.6,54.9C98,151.5,73.4,145,62.3,125.8c-0.4-0.7-0.8-1.4-1.1-2.1
	L7.3,152.9l139.4,257.4L429,257.4L289.6,0z"
          />
          <circle class="st1" cx="94.6" cy="105.7" r="32.6" />
          <path
            class="st0"
            d="M686,689.1l170.7-55.7c-7.5-20.9,3.3-43.9,24.2-51.4s43.9,3.3,51.4,24.2c0.3,0.8,0.5,1.5,0.7,2.3l58.2-19
	l-90.8-278.3l-305.2,99.5L686,689.1z"
          />
          <circle class="st1" cx="896.8" cy="620.2" r="32.6" />
          <rect x="115.5" y="152.7" class="st2" width="788" height="358" />
          <rect x="128.5" y="165.7" class="st3" width="762" height="325" />
          <rect x="739" y="475.2" class="st2" width="74" height="25" />
          <rect x="681" y="494.2" class="st1" width="54" height="7" />
          <polyline
            class="st4"
            points="190.5,288.7 481.5,288.7 526.6,310.7 598.5,345.7 645.5,257.7 778.5,257.7 "
          />
          <polyline class="st4" points="304.9,288.7 326.5,339.7 379.5,339.7 " />
          <polyline class="st4" points="385.8,288.7 459.5,248.7 511.5,248.7 " />
          <circle class="st1" cx="304.5" cy="288.7" r="9" />
          <circle class="st1" cx="388.5" cy="288.7" r="9" />
          <polyline class="st4" points="526.5,310.7 532.5,399.7 584.5,399.7 " />
          <polyline class="st4" points="626.7,293 569.5,248.7 623.5,248.7 " />
          <polyline class="st4" points="721.1,257.7 776.5,301.7 828.5,301.7 " />
          <circle class="st1" cx="526.5" cy="310.7" r="9" />
          <circle class="st1" cx="720.5" cy="257.7" r="9" />
          <circle class="st1" cx="626.5" cy="292.7" r="9" />
          <line class="st4" x1="327.5" y1="347.7" x2="379.5" y2="347.7" />
          <path class="st4" d="M511.5,257.7L511.5,257.7z" />
          <path class="st4" d="M623.5,256.7L623.5,256.7z" />
          <path class="st4" d="M623.5,264.7L623.5,264.7z" />
          <path class="st4" d="M828.5,309.7L828.5,309.7z" />
          <path class="st4" d="M809.5,317.7L809.5,317.7z" />
          <path class="st4" d="M584.5,407.7L584.5,407.7z" />
          <circle class="st4" cx="809.5" cy="75.5" r="14.8" />
          <ellipse class="st0" cx="152" cy="742.2" rx="152" ry="28" />
          <polygon
            class="st1"
            points="256.4,493.7 229.3,507.3 231,510.8 258.1,497.2 "
          />
          <path
            class="st5"
            d="M252,468.2l-1.2,7.3c0,0,1.8,50.6-11.6,34.7s-4.3-37.2-4.3-37.2l3.7-6.1L252,468.2z"
          />
          <path
            class="st5"
            d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
          />
          <path
            class="st6"
            d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
          />
          <polygon
            class="st2"
            points="128.9,700.9 128.9,722.8 120.4,724 108.2,720.4 111.9,697.2 "
          />
          <path
            class="st7"
            d="M118,711.9c0,0-3.7-9.7-9.7-8.5s-7.3,8.5-7.3,8.5s-4.9,18.3-12.2,24.4s-14.6,21.9,0,24.4s26.8-3.7,26.8-4.9
	s3.7-8.5,9.7-9.7c6.1-1.2,11-2.4,11-7.3s-3.7-9.7-3.7-9.7s-1.2-23.1-3.7-20.1c-1.7,2.4-2.9,5.1-3.7,7.9L118,711.9z"
          />
          <polygon
            class="st2"
            points="181.3,689.9 176.4,708.2 188.6,710.6 194.7,704.5 195.9,688.7 "
          />
          <path
            class="st7"
            d="M180.1,705.8c0,0,4.9-3.7,7.3-3.7h3.7c0,0,0-8.5,6.1-8.5s4.9,6.1,4.9,6.1s13.4,20.7,17.1,24.4
	s23.1,14.6,7.3,19.5s-26.8,4.9-34.1-3.7c0,0-7.3-3.7-12.2-3.7s-11-2.4-11-6.1s3.7-11,3.7-11s2.4-21.9,3.7-19.5
	S180.1,705.8,180.1,705.8z"
          />
          <path
            class="st7"
            d="M127.7,409.7v40.2c0,0-13.4,78-13.4,103.5s-20.7,147.4-11,149.8s32.9,6.1,34.1,2.4s9.7-119.4,11-120.6
	c1.2-1.2,26.8-97.5,26.8-97.5l4.9,1.2l2.4,109.6c0,0-12.2,98.7-6.1,98.7s31.7-1.2,31.7-8.5s13.4-142.5,13.4-142.5
	S237.4,439,231.3,428s-6.1-17.1-6.1-17.1L127.7,409.7z"
          />
          <circle class="st5" cx="194.1" cy="228.8" r="24.4" />
          <path
            class="st8"
            d="M188.6,270.9c0,0-15.2-12.8-16.4-17.7c0,0-7.9,7.9-7.9,9.1s-34.1,4.9-42.6,14.6s-15.8,30.5-15.8,30.5l20.7,39
	c0,0,1.2,21.9,1.2,24.4s-6.1,41.4-3.7,42.6s26.8,13.4,47.5,11s41.4-6.1,47.5-6.1s11,2.4,11,0s-3.7-7.3-3.7-9.7s-1.2-8.5-1.2-12.2
	s-2.4-9.7-2.4-14.6s26.8-70.7,26.8-70.7s-1.2-20.7-20.7-30.5s-21.9-13.4-21.9-13.4s-0.4-7.3-1.4-7.3s-4.7,11-14.4,12.2L188.6,270.9z
	"
          />
          <polygon
            class="st5"
            points="122.8,432.9 131.4,446.3 153.3,441.4 137.5,423.1 "
          />
          <path
            class="st8"
            d="M242.2,301.3l7.3,9.7c0,0,14.6,70.7,12.2,96.2s-4.9,45.1-4.9,45.1s4.9,18.3,1.2,18.3s-25.6,1.2-25.6-2.4
	s2.4-26.8,2.4-26.8l-6.1-53.6l-13.4-18.3L242.2,301.3z"
          />
          <path
            class="st7"
            d="M177.6,217c1.5-0.6,3.2-0.1,4.8-0.4c1.9-0.3,3.6-1.7,5.5-1.5c0.9,0.2,1.8,0.5,2.6,0.8c0.8,0.4,1.8,0.4,2.7,0.1
	c0.8-0.4,1.1-1.3,0.7-2c0-0.1-0.1-0.2-0.2-0.3c1.6-0.1,3.1-0.8,4.3-1.9c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.1,2.1,0.3l2.8,1.3
	c0.6,0.2,1.2,0.6,1.6,1.1c0.3,0.5,0.4,1.2,0.9,1.6c0.6,0.5,1.5,0.3,2.3,0.3c1.5,0.1,2.6,1.4,2.6,2.8c0,0.8-0.3,1.8,0.3,2.3
	c0.4,0.3,0.9,0.2,1.3,0.4c1.1,0.5,1.1,2,1,3.3s-0.1,2.8,1.1,3.2c0.8,0.3,1.7-0.3,2.5-0.4c1.3-0.2,2.5,0.6,3.6,1.2s2.7,1,3.7,0.2
	c0.9-0.8,0.9-2.2,0.6-3.4c-0.4-1.7-1-3.3-1.8-4.9c-0.3-0.5-0.5-1-0.5-1.5c0.1-0.8,0.6-1.5,1.3-1.9c1.4-1.1,3-2.2,3.5-4
	c0.3-1.3,0-2.6,0-3.9c0-1.6,0.7-3.2,0.4-4.8c-0.5-2.9-3.7-4.2-5.8-6.3c-2.8-2.8-3.7-7.4-7.1-9.6c-2.7-1.8-6.1-1.6-9.2-2.6
	c-3.3-1.1-6-3.7-9.3-4.9c-3.6-1.3-7.7-1-11,1c-1,0.7-2.1,1.3-3.2,1.7c-1,0.1-2,0.1-2.9,0c-1.9-0.1-3.9,0.3-5.6,1.2
	c-0.7,0.3-1.2,0.8-1.6,1.4c-0.6,1.3,0.3,3.4-1,4c-0.6,0.3-1.3,0-2,0c-1.3,0-2.3,1.2-2.9,2.3c-0.5,1.2-1.2,2.3-2,3.3
	c-1.6,1.4-4.5,1.6-4.9,3.7c0,0.9,0,1.8,0.1,2.6c-0.1,1.8-2.1,3.2-2,5.1c0.1,2.1,2.8,3.2,3.5,5.1c0.5,1.5-0.2,3.2-0.7,4.7
	s-0.6,3.5,0.6,4.5c0.5,0.4,1.1,0.5,1.7,0.9c1.3,1,1.3,2.9,1.2,4.5c-0.2,0.7,0,1.4,0.5,1.9c0.4,0.3,1,0.3,1.5,0.2
	c1.6-0.4,3-1.4,3.8-2.9c0.4-0.7,0.5-1.3,1.3-1.7c0.5-0.3,1.2,0,1.7-0.4C175.3,222.8,174.4,218.3,177.6,217z"
          />
          <path
            class="st8"
            d="M109.4,303.7l-3.7,3.7c0,0-21.9,65.8-14.6,80.4s21.9,51.2,26.8,49.9s21.9-12.2,21.9-13.4S119.2,389,119.2,389
	s-2.4-7.3-1.2-8.5s1.2,0,0-2.4s-1.2,0,1.2-2.4s2.4-2.4,2.4-3.7s7.3-31.7,7.3-31.7L109.4,303.7z"
          />
          <polygon
            class="st7"
            points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
          />
          <polygon
            class="st6"
            points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
          />
          <polygon class="st6" points="164.9,262.9 185.6,280 188,276.3 " />
          <polygon class="st6" points="206.3,267.8 191.7,276.3 192.9,280 " />
        </svg>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <form @:submit.prevent="onSubmit()" class="login-form" id="login-form">
          <section class="form-page" id="login-form">
            <h4 class="text-center form-header">{{ $t("Login") }}</h4>
            <p class="text-center error--text" v-if="errorMsg">
              {{ $t(errorMsg) }}
            </p>
            <div class="form-group">
              <label for="first-name"
                >{{ $t("Email") }} / {{ $t("Mobile") }}</label
              >
              <v-text-field
                type="text"
                id="inputEmail"
                solo
                required
                :placeholder="$t('Please enter your email or mobile')"
                v-model.trim="$v.user.email.$model"
                @focus="onfocus()"
              ></v-text-field>

              <p
                class="error--text"
                v-if="$v.user.email.$error && !$v.user.email.required"
              >
                {{ $t("This field is Required") }}
              </p>
              <!-- <p
                class="error--text"
                v-if="$v.user.email.$error && !$v.user.email.email"
              >
                Please enter a valid email address.
              </p>-->
              <!-- <div v-if="validation_errors.en">
                <p
                  class="error--text"
                  v-for="(error, index) in validation_errors.en
                    .student_first_name"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>-->
            </div>
            <div class="form-group">
              <label for="second-name">{{ $t("Password") }}</label>
              <v-text-field
                id="inputPassword"
                solo
                required="required"
                :placeholder="$t('Please enter your password')"
                v-model.trim="$v.user.password.$model"
                @focus="onfocus()"
                :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              ></v-text-field>
              <br />
              <div clas="clearfix">
                <a class="main-color forget_password" @click="resetPassword">{{
                  $t("Forgot Password?")
                }}</a>
              </div>

              <p
                class="error--text"
                v-if="$v.user.password.$error && !$v.user.password.required"
              >
                {{ $t("This field is Required") }}
              </p>
            </div>
            <div class="text-center clearfix">
              <v-btn
                type="submit"
                id="submit"
                class="ui-btn submit"
                @click="submitLogin($event)"
                :disabled="$v.user.$invalid || loading"
                :loading="loading"
                large
              >
                {{ $t("Login") }}
              </v-btn>
            </div>
          </section>
        </form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { CheckLogin } from "../mixins/CheckLogin";
// import ACL from "../acl";
// import Acl from "../acl";
export default {
  name: "LoginView",
  mixins: [CheckLogin],
  data() {
    return {
      loading: false,
      lang: this.currentAppLocale,
      value: true,
      message: "",
      user: { email: "", password: "" },
      errorMsg: "",
    };
  },
  validations: {
    user: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    resetPassword() {
      this.$router.push({ path: `/ResetPassword` });
    },
    submitLogin(event) {
      // validation form
      let newUser = {
        email: this.user.email,
        password: this.user.password,
      };
      event.preventDefault();
      this.loading = true;
      axios.post(this.getApiUrl + "/login", newUser).then((response) => {
        this.loading = false;
        if (response.data.status.error == false) {
          //get user id from response returned
          let id = response.data.data.user.id;
          let token = response.data.data.access_token;
          let school = response.data.data.school;
          let logo = response.data.data.logo;
          let school_name = response.data.data.school_name;
          let gradeClass = response.data.data.gradeClass;

          localStorage.user_name = response.data.data.user.name;
          // localStorage to store user cradentials
          localStorage.id = id;
          localStorage.email = newUser.email;
          localStorage.lastLogin = response.data.data.lastLogin;
          localStorage.login = true;
          localStorage.token = token;
          localStorage.school = school;
          localStorage.logo = logo;
          localStorage.school_name = school_name;
          localStorage.gradeClass = gradeClass;
          localStorage.user_name = response.data.data.user_name;
          localStorage.user_logo = response.data.data.user_logo;

          localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
          // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
          localStorage.permissionsObject = JSON.stringify(
            response.data.data.permissions
          );
          localStorage.menus = JSON.stringify(response.data.data.menus);
          localStorage.roles = JSON.stringify(response.data.data.roles);
          if (
            response.data.data.roles &&
            response.data.data.roles.length == 1
          ) {
            localStorage.selectedRole = response.data.data.roles[0];
          }
          localStorage.selectedSomeRole = false;
          localStorage.loginAs = response.data.data.login_as;
          localStorage.is_employee = response.data.data.is_employee;
          if (localStorage.loginAs) {
            localStorage.old_user = response.data.data.old_user;
          }
          localStorage.updateData = response.data.data.updateData;
          if (this.currentAppLocale == "ar") {
            this.currentAppLocale = "ar";
          } else {
            this.currentAppLocale == "en";
          }
          // if success route to dashboard
          // console.log(localStorage.selectedRole);
          // if (
          //   (localStorage.selectedRole == "Super-Admin" ||
          //     localStorage.selectedRole == "Coordinator") &&
          //   ACL.checkMenu("Dashboard")
          // ) {
          //   this.$router.push({ path: `/adminDashboard` });
          // } else if (
          //   localStorage.selectedRole == "Parent" &&
          //   ACL.checkMenu("Dashboard")
          // ) {
          //   this.$router.push({ path: `/parentDashboard` });
          // } else if (
          //   localStorage.selectedRole == "Student" &&
          //   ACL.checkMenu("Dashboard")
          // ) {
          //   this.$router.push({ path: `/studentDashboard` });
          // } else if (
          //   localStorage.selectedRole == "Teacher" &&
          //   ACL.checkMenu("Dashboard")
          // ) {
          //   this.$router.push({ path: `/teacherDashboard` });
          // } else {
          // hasZoomClassNow
          // zoomMeetingNumber
          console.log(
            "response.data.data.hasZoomClassNow",
            response.data.data.hasZoomClassNow
          );
          console.log(
            "response.data.data.zoomMeetingNumber",
            response.data.data.zoomMeetingNumber
          );
          if (
            response.data.data.hasZoomClassNow &&
            response.data.data.zoomMeetingNumber
          ) {
            window.location =
              "/zoomIframe/" + response.data.data.zoomMeetingNumber;
            // this.$router.push({
            //   path: "/zoomIframe/" + response.data.data.zoomMeetingNumber,
            // });
          } else if (
            response.data.data.hasExamNow &&
            response.data.data.examId
          ) {
            window.location = "/examTimer/" + response.data.data.examId;
          }
          if (this.$router.currentRoute.query.redirectTo) {
            this.$router.push({
              path: `/` + this.$router.currentRoute.query.redirectTo,
            });
          } else {
            this.$router.push({ path: `/` });
          }

          // }
        } else {
          // console.log(response);
          this.errorMsg = response.data.status.message;

          localStorage.login = false;
        }
      });
    },
    onfocus() {
      this.errorMsg = "";
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../styles/_variables.scss";
@import "../styles/_forms.scss";

.form-group .login-btn:disabled {
  cursor: not-allowed !important;
  background: webkit linear-gradient(to top, #bdc5ce, #dce3e8) !important;
  background: linear-gradient(to top, #bdc5ce, #dce3e8) !important;
}

.header-main-menu
  .navbar-nav
  .header-admin
  .dropdown-menu
  .item-content
  .settings-list
  li
  a {
  cursor: pointer !important;
}
.login-page-content .login-box .login-form .form-group {
  margin-bottom: 5px !important;
}
.dashboard-content-one {
  padding: 0 !important;
}
.forget_password {
  margin-top: -5%;
  float: right;
  text-decoration: underline;
}
.clearfix {
  overflow: auto;
  clear: both;
}
</style>
